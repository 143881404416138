import axios from "@/assets/helpers/api";

export default {
  state: {
    selectedPlan: {},
    promo_code: {},
    uuid: false,
    plansList: {},
    target: "",
    selectedCityNeighborhoods: [],
    selectedNeighborhoodStreets: [],
    uuid: false,
    cepError: false,
  },

  getters: {
    getSelectedPlan: (state) => state.selectedPlan,

    getSelectedCity: (state) => state.selectedPlan.city,

    getSelectedCityNeighborhoods: (state) => state.selectedCityNeighborhoods,

    getSelectedNeighborhoodStreets: (state) =>
      state.selectedNeighborhoodStreets,

    getPlansList: (state) => state.plansList,

    getTarget: (state) => state.target,

    getCepError: (state) => state.cepError,

    getDiscount: (state) => state.promo_code,
  },

  actions: {
    fetchSession: async (_, sessionUuid) => {

      try {
        const { data } = await axios.get(`/session/${sessionUuid}`);
        return data;
      } catch (e) {
        console.log({ error: e });
      }

    },

    fetchSelectedPlanBySession: async ({ commit, dispatch }, sessionUuid) => {
      try {
        const { data } = await axios.get(`/session/${sessionUuid}`);

        dispatch("fetchPlansList", data.session.session_uuid);
        commit("setSelectedPlan", data);
        return data;
      } catch (e) {
        console.log({ error: e });
      }
    },

    fetchSelectedPlan: async (
      { commit, dispatch },
      {
        selectedCity,
        selectedPlan,
        selectedPlanType,
        visitorId,
        target,
        promoCode,
        affiliate
      }
    ) => {
      try {
        const { data: plan } = await axios.post("/session/start", {
          city: selectedCity,
          product: selectedPlan,
          type: selectedPlanType,
          visitor_id: visitorId,
          target: target,
          promo_code: promoCode,
          'tracker': affiliate
        });

        dispatch("fetchPlansList", plan.session.session_uuid);
        commit("setSelectedPlan", plan);
      } catch (e) {
        console.log({ error: e });
        //window.location = 'https://weclix.com.br'
      }
    },

    fetchPlansList: async ({ commit }, sessionUuid) => {
      try {
        const { data: plansList } = await axios.get(`/products/${sessionUuid}`);
        commit("setPlansList", plansList);
      } catch (e) {
        console.log({ error: e });
      }
    },

    fetchSelectedCityNeighborhoods: async (
      { commit },
      { sessionUuid, cep }
    ) => {
      commit("setCepError", false);
      try {
        const { data: neighborhoods } = await axios.get(
          `/availability/${sessionUuid}/neighborhoods/cep/${cep}`
        );
        if (!neighborhoods.length) {
          commit("setCepError", true);
        }
        commit("setSelectedCityNeighborhoods", neighborhoods);
      } catch (e) {
        console.log({ error: e });
      }
    },

    fetchSelectedNeighborhoodStreets: async (
      { commit },
      { sessionUuid, neighborhood, cep }
    ) => {
      try {
        const { data: streets } = await axios.get(
          `/availability/${sessionUuid}/neighborhoods/${neighborhood}/streets/cep/${cep}`
        );

        commit("setSelectedNeighborhoodStreets", streets);
      } catch (e) {
        console.log({ error: e });
      }
    },

    checkAvailability: async ({ commit }, { formData, sessionUuid }) => {
      try {
        const { data } = await axios.post(
          `/availability/${sessionUuid}/check`,
          {
            ...formData,
            addressId: +formData.addressId,
          }
        );

        return data;
      } catch (e) {
        console.log(e);
        return e.response.data;
      }
    },

    checkPromoCode: async ({ state, commit }, { sessionUuid, code }) => {
      try {
        const { data } = await axios.post(
          `/payment/${sessionUuid}/promo-code`,
          { code }
        );

        if (data.success) {
          commit("usePromoCode", {
            promo_code: data.promo_code,
          });
        }

        return data.success;
      } catch (e) {
        console.log({ error: e });
      }
    },

    setSelectedPlan: ({ commit }, plan) => {
      commit("setSelectedPlan", plan);
    },

    setTarget: ({ commit }, target) => commit("setTarget", target),

    setCepError: ({ commit }, cepError) => commit("setCepError", cepError),

    updateSelectedPlan: async ({ commit }, { sessionUuid, plan }) => {
      try {
        const { data } = await axios.patch(`/session/${sessionUuid}`, {
          selected_plan_id: plan.id,
          selected_plan_type: plan.combo ? "combo" : "plan",
        });
        commit("setSelectedPlan", data);
      } catch (e) {
        console.log({ error: e });
      }
    },

    updatePlanOrCity: async ({ commit }, { sessionUuid, city, type, product }) => {      

      try {
        const { data } = await axios.patch(`/session/${sessionUuid}`, {
          city,
          product,
          type,
        });

        commit("setSelectedPlan", data);

      } catch (e) {
        console.log({ error: e });
      }
    },

  },

  mutations: {
    
    setSelectedPlan: (state, { product, session, city, promo_code, price, full_price, available_cities }) => {
      state.selectedPlan = { product, city, price:{ price, full_price, },  available_cities };
      localStorage.setItem("session_uuid", session.session_uuid);
      localStorage.setItem("selected_plan_id", session.selected_plan_id);
      state.target = session.target;
      state.uuid = session.session_uuid;

      if (promo_code) {
        state.promo_code = promo_code;
      }
    },

    setCepError: (state, cepError) => {
      state.cepError = cepError;
    },

    usePromoCode: (state, { promo_code }) => {
      state.promo_code = promo_code;
    },

    setSelectedCityNeighborhoods: (state, neighborhoodsList) =>
      (state.selectedCityNeighborhoods = neighborhoodsList),

    setSelectedNeighborhoodStreets: (state, streetsList) =>
      (state.selectedNeighborhoodStreets = streetsList),

    setPlansList: (state, plansList) => (state.plansList = plansList),

    setTarget: (state, target) => (state.target = target),
  },
};
