<template>
  <div v-bind="$attrs" class="we-header w-full pb-20 py-10 md:pb-10 px-6 md:px-24 desktop-layout__header flex justify-between items-center" :style="headerBgColor">
    <img :src="logo" class="desktop-layout__logo">
    <div class="flex items-center text-primary-brand-400 font-semibold cursor-pointer" @click="goToSupportService">
      <we-icon name="whatsapp" classes="fill-primary-brand-400" class="mr-1"/>
      <span>Suporte de Vendas</span>
    </div>
  </div>
</template>

<script setup>
  import { computed } from 'vue'
  import { useRouter } from 'vue-router'
  import { useScreen } from '@/composables'
  import WeIcon from '../components/WeIcon.vue'

  import brandLogo from "@/assets/images/brand-logo.svg"
  import businessLogo from "@/assets/images/business-logo.svg"

  const router = useRouter()

  const props = defineProps({
    target: {
      type: String,
      default: undefined
    },
  })

  const { isSmall } = useScreen()

  const logo = computed(()=>{
    return props.target == "business" ? businessLogo : brandLogo
  })

  const headerBgColor = computed(() => {
    if(props.target == "business"){
      return 'background-color: #FFFFFF'
    } else {
      return isSmall.value ? 'background-color: #F0E500' : 'background: linear-gradient(to right, #fff 50.01%, #F0E500 49.99%)'
    }  
  })

  //header
  const goToSupportService = () => window.open('https://api.whatsapp.com/send?phone=5516988541897', '_blank')
</script>

<style lang="scss" scoped>

</style>